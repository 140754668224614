import { Link } from "react-router-dom";
import CallToAction from "../components/CallToAction";
export default function Services() {
  return (
    <>
      <main className="main">
        {/* Page Title */}
        <div
          className="page-title dark-background"
          data-aos="fade"
          style={{
            backgroundImage: "url(assets/img/services-page-title-bg.jpg)",
          }}
        >
          <div className="container">
            <h1>Services</h1>
            <nav className="breadcrumbs">
              <ol>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="current">Services</li>
              </ol>
            </nav>
          </div>
        </div>
        {/* End Page Title */}
        {/* Services Section */}
        <section id="services" className="services section">
          {/* Section Title */}
          <div className="container section-title" data-aos="fade-up">
            <h2>Our Services</h2>
            <p>
              At Global Link, we offer a comprehensive range of IT services designed to meet the unique needs of your business. Our solutions are tailored to drive innovation, enhance efficiency, and create lasting value. Explore our diverse service offerings below:
            </p>
          </div>
          {/* End Section Title */}
          <div className="container">
            <div className="row gy-4">
              {/* IT Consulting */}
              <div className="col-lg-4 col-md-6 service-item d-flex" data-aos="fade-up" data-aos-delay={100}>
                <div className="icon flex-shrink-0">
                  <i className="bi bi-briefcase" style={{ color: "#56b8e6" }} />
                </div>
                <div>
                  <h4 className="title">IT Consulting</h4>
                  <p className="description" align="justify">
                    Our IT consulting services provide comprehensive guidance to navigate the digital landscape. We develop tailored IT strategies, integrate cutting-edge systems, and optimize your technology investments. Our experts work closely with you to align IT initiatives with your business goals, ensuring seamless implementation and long-term success.
                  </p>
                  {/* <a href="#" className="readmore stretched-link">
                    <span>Learn More</span>
                    <i className="bi bi-arrow-right" />
                  </a> */}
                </div>
              </div>

              {/* Big Data */}
              <div className="col-lg-4 col-md-6 service-item d-flex" data-aos="fade-up" data-aos-delay={200}>
                <div className="icon flex-shrink-0">
                  <i className="bi bi-bar-chart" style={{ color: "#56b8e6" }} />
                </div>
                <div>
                  <h4 className="title">Big Data</h4>
                  <p className="description" align="justify">
                    Unlock the power of vast data sets with our Big Data solutions. We help you store, process, and analyze large volumes of data using advanced technologies like Hadoop, Spark, and NoSQL databases. Transform raw data into valuable insights to drive strategic decisions, improve operations, and gain a competitive edge.
                  </p>
                  {/* <a href="#" className="readmore stretched-link">
                    <span>Learn More</span>
                    <i className="bi bi-arrow-right" />
                  </a> */}
                </div>
              </div>

              {/* Business Intelligence */}
              <div className="col-lg-4 col-md-6 service-item d-flex" data-aos="fade-up" data-aos-delay={300}>
                <div className="icon flex-shrink-0">
                  <i className="bi bi-graph-up" style={{ color: "#56b8e6" }} />
                </div>
                <div>
                  <h4 className="title">Business Intelligence</h4>
                  <p className="description" align="justify">
                    Transform your data into actionable insights with our Business Intelligence services. Utilizing tools like Power BI, Tableau, and QlikView, we create intuitive dashboards and reports. Monitor key performance indicators, identify trends, and make data-driven decisions that propel your business forward.
                  </p>
                  {/* <a href="#" className="readmore stretched-link">
                    <span>Learn More</span>
                    <i className="bi bi-arrow-right" />
                  </a> */}
                </div>
              </div>

              {/* Data Analytics */}
              <div className="col-lg-4 col-md-6 service-item d-flex" data-aos="fade-up" data-aos-delay={400}>
                <div className="icon flex-shrink-0">
                  <i className="bi bi-pie-chart" style={{ color: "#56b8e6" }} />
                </div>
                <div>
                  <h4 className="title">Data Analytics</h4>
                  <p className="description" align="justify">
                    Gain deeper insights into your business with our data analytics services. We offer descriptive, predictive, and prescriptive analytics using advanced statistical methods and machine learning. Understand past performance, forecast future trends, and optimize decision-making to drive success.
                  </p>
                  {/* <a href="#" className="readmore stretched-link">
                    <span>Learn More</span>
                    <i className="bi bi-arrow-right" />
                  </a> */}
                </div>
              </div>

              {/* Infrastructure Management */}
              <div className="col-lg-4 col-md-6 service-item d-flex" data-aos="fade-up" data-aos-delay={500}>
                <div className="icon flex-shrink-0">
                  <i className="bi bi-server" style={{ color: "#56b8e6" }} />
                </div>
                <div>
                  <h4 className="title">Infrastructure Management</h4>
                  <p className="description" align="justify">
                    Ensure the seamless operation of your IT infrastructure with our management services. We cover network management, server maintenance, cloud solutions, and disaster recovery. Our proactive approach minimizes downtime, optimizes performance, and protects your infrastructure from threats.
                  </p>
                  {/* <a href="#" className="readmore stretched-link">
                    <span>Learn More</span>
                    <i className="bi bi-arrow-right" />
                  </a> */}
                </div>
              </div>

              {/* Digital Transformation */}
              <div className="col-lg-4 col-md-6 service-item d-flex" data-aos="fade-up" data-aos-delay={600}>
                <div className="icon flex-shrink-0">
                  <i className="bi bi-lightbulb" style={{ color: "#56b8e6" }} />
                </div>
                <div>
                  <h4 className="title">Digital Transformation</h4>
                  <p className="description" align="justify">
                    Modernize your business with our digital transformation services. We help you adopt new technologies, automate workflows, and create a digital-first culture. Enhance customer experiences, improve operational efficiency, and drive innovation with our tailored solutions.
                  </p>
                  {/* <a href="#" className="readmore stretched-link">
                    <span>Learn More</span>
                    <i className="bi bi-arrow-right" />
                  </a> */}
                </div>
              </div>

              {/* Managed Services */}
              <div className="col-lg-4 col-md-6 service-item d-flex" data-aos="fade-up" data-aos-delay={700}>
                <div className="icon flex-shrink-0">
                  <i className="bi bi-gear" style={{ color: "#56b8e6" }} />
                </div>
                <div>
                  <h4 className="title">Managed Services</h4>
                  <p className="description" align="justify">
                    Focus on your core business while we handle your IT operations. Our managed services include 24/7 monitoring, IT support, patch management, and backup and recovery. We ensure uninterrupted business continuity and optimal performance of your IT systems, reducing stress and controlling costs.
                  </p>
                  {/* <a href="#" className="readmore stretched-link">
                    <span>Learn More</span>
                    <i className="bi bi-arrow-right" />
                  </a> */}
                </div>
              </div>

              {/* DevOps */}
              <div className="col-lg-4 col-md-6 service-item d-flex" data-aos="fade-up" data-aos-delay={800}>
                <div className="icon flex-shrink-0">
                  <i className="bi bi-code-slash" style={{ color: "#56b8e6" }} />
                </div>
                <div>
                  <h4 className="title">DevOps</h4>
                  <p className="description" align="justify">
                    Accelerate your software development lifecycle with our DevOps solutions. We offer continuous integration, deployment, and automated testing. Enhance collaboration between development and operations teams, delivering software faster and with higher quality using tools like Jenkins, Git, Docker, and Kubernetes.
                  </p>
                  {/* <a href="#" className="readmore stretched-link">
                    <span>Learn More</span>
                    <i className="bi bi-arrow-right" />
                  </a> */}
                </div>
              </div>

              {/* CRM */}
              <div className="col-lg-4 col-md-6 service-item d-flex" data-aos="fade-up" data-aos-delay={900}>
                <div className="icon flex-shrink-0">
                  <i className="bi bi-people" style={{ color: "#56b8e6" }} />
                </div>
                <div>
                  <h4 className="title">CRM</h4>
                  <p className="description" align="justify">
                    Enhance your customer relationships with our CRM solutions. We provide customized services to manage interactions, improve sales processes, and deliver personalized experiences. Utilize platforms like Salesforce, Microsoft Dynamics, and HubSpot to boost customer satisfaction, loyalty, and lifetime value.
                  </p>
                  {/* <a href="#" className="readmore stretched-link">
                    <span>Learn More</span>
                    <i className="bi bi-arrow-right" />
                  </a> */}
                </div>
              </div>

              {/* ERP */}
              <div className="col-lg-4 col-md-6 service-item d-flex" data-aos="fade-up" data-aos-delay={1000}>
                <div className="icon flex-shrink-0">
                  <i className="bi bi-grid" style={{ color: "#56b8e6" }} />
                </div>
                <div>
                  <h4 className="title">ERP</h4>
                  <p className="description" align="justify">
                    Optimize your business operations with our ERP solutions. We integrate various functions, including finance, HR, supply chain, and manufacturing, into a single system. Leverage platforms like SAP, Oracle, and Microsoft Dynamics to improve efficiency, reduce costs, and gain greater visibility into your processes.
                  </p>
                  {/* <a href="#" className="readmore stretched-link">
                    <span>Learn More</span>
                    <i className="bi bi-arrow-right" />
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* /Services Section */}
        <CallToAction />
      </main>
    </>
  );
}
