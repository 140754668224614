import { Link } from "react-router-dom";
import CallToAction from "../components/CallToAction";
export default function Industries() {
  return (
    <>
      <main className="main">
        {/* Page Title */}
        <div
          className="page-title dark-background"
          data-aos="fade"
          style={{
            backgroundImage: "url(assets/img/services-page-title-bg.jpg)",
          }}
        >
          <div className="container">
            <h1>Industries</h1>
            <nav className="breadcrumbs">
              <ol>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="current">Industries</li>
              </ol>
            </nav>
          </div>
        </div>
        {/* End Page Title */}
        {/* Services Section */}
        <section id="services" className="services section">
          {/* Section Title */}
          <div className="container section-title" data-aos="fade-up">
            <h2>Industries We Serve</h2>
            <p>
            At Global Link, we provide innovative IT solutions across a variety of industries, helping businesses and organizations achieve their goals through technology.
            </p>
          </div>
          {/* End Section Title */}
          <div className="container">
            <div className="row row gy-4">
              {/* Automotive */}
              <div className="col-lg-4 col-md-6 service-item d-flex" data-aos="fade-up" data-aos-delay={100}>
                <div className="icon flex-shrink-0">
                  <i className="bi bi-car-front" style={{ color: "#56b8e6" }} />
                </div>
                <div>
                  <h4 className="title">Automotive</h4>
                  <p className="description" align="justify">
                    Revolutionizing the automotive industry with advanced IT solutions that enhance every aspect of vehicle design, manufacturing, and customer interaction. Our expertise spans connected vehicles, autonomous driving technologies, and smart factory integrations, helping you stay ahead in a rapidly evolving market.
                  </p>
                  {/* <a href="#" className="readmore stretched-link">
                    <span>Learn More</span>
                    <i className="bi bi-arrow-right" />
                  </a> */}
                </div>
              </div>

              {/* Banking */}
              <div className="col-lg-4 col-md-6 service-item d-flex" data-aos="fade-up" data-aos-delay={200}>
                <div className="icon flex-shrink-0">
                  <i className="bi bi-bank" style={{ color: "#56b8e6" }} />
                </div>
                <div>
                  <h4 className="title">Banking</h4>
                  <p className="description" align="justify">
                    Transforming banking operations with secure, scalable, and innovative IT solutions. Our offerings include digital banking platforms, fraud detection systems, and advanced analytics, ensuring your bank operates efficiently while providing customers with seamless, secure banking experiences.
                  </p>
                  {/* <a href="#" className="readmore stretched-link">
                    <span>Learn More</span>
                    <i className="bi bi-arrow-right" />
                  </a> */}
                </div>
              </div>

              {/* Financial Services */}
              <div className="col-lg-4 col-md-6 service-item d-flex" data-aos="fade-up" data-aos-delay={300}>
                <div className="icon flex-shrink-0">
                  <i className="bi bi-currency-exchange" style={{ color: "#56b8e6" }} />
                </div>
                <div>
                  <h4 className="title">Financial Services</h4>
                  <p className="description" align="justify">
                    Providing robust IT solutions tailored for the financial services sector. We offer comprehensive risk management tools, compliance frameworks, and financial analytics platforms, enabling institutions to optimize operations, ensure regulatory adherence, and gain deep insights into financial data.
                  </p>
                  {/* <a href="#" className="readmore stretched-link">
                    <span>Learn More</span>
                    <i className="bi bi-arrow-right" />
                  </a> */}
                </div>
              </div>

              {/* Telecommunications */}
              <div className="col-lg-4 col-md-6 service-item d-flex" data-aos="fade-up" data-aos-delay={400}>
                <div className="icon flex-shrink-0">
                  <i className="bi bi-phone" style={{ color: "#56b8e6" }} />
                </div>
                <div>
                  <h4 className="title">Telecommunications</h4>
                  <p className="description" align="justify">
                    Enhancing telecommunications with innovative IT solutions designed to boost network performance and customer engagement. Our services include network optimization, customer relationship management, and digital service delivery platforms, enabling telecom providers to offer superior and reliable services.
                  </p>
                  {/* <a href="#" className="readmore stretched-link">
                    <span>Learn More</span>
                    <i className="bi bi-arrow-right" />
                  </a> */}
                </div>
              </div>

              {/* Healthcare */}
              <div className="col-lg-4 col-md-6 service-item d-flex" data-aos="fade-up" data-aos-delay={500}>
                <div className="icon flex-shrink-0">
                  <i className="bi bi-heart-pulse" style={{ color: "#56b8e6" }} />
                </div>
                <div>
                  <h4 className="title">Healthcare</h4>
                  <p className="description" align="justify">
                    Transforming healthcare delivery with IT solutions that improve patient care, enhance operational efficiency, and facilitate better health outcomes. Our services include electronic health records (EHR), telemedicine platforms, and healthcare analytics to support informed decision-making and patient-centric care.
                  </p>
                  {/* <a href="#" className="readmore stretched-link">
                    <span>Learn More</span>
                    <i className="bi bi-arrow-right" />
                  </a> */}
                </div>
              </div>

              {/* Insurance */}
              <div className="col-lg-4 col-md-6 service-item d-flex" data-aos="fade-up" data-aos-delay={600}>
                <div className="icon flex-shrink-0">
                  <i className="bi bi-shield-check" style={{ color: "#56b8e6" }} />
                </div>
                <div>
                  <h4 className="title">Insurance</h4>
                  <p className="description" align="justify">
                    Enhancing the insurance industry with innovative IT solutions that streamline operations and improve customer satisfaction. Our offerings include claims management systems, underwriting solutions, and customer engagement platforms, helping insurers manage risk, reduce costs, and enhance service delivery.
                  </p>
                  {/* <a href="#" className="readmore stretched-link">
                    <span>Learn More</span>
                    <i className="bi bi-arrow-right" />
                  </a> */}
                </div>
              </div>

              {/* Retail */}
              <div className="col-lg-4 col-md-6 service-item d-flex" data-aos="fade-up" data-aos-delay={800}>
                <div className="icon flex-shrink-0">
                  <i className="bi bi-shop" style={{ color: "#56b8e6" }} />
                </div>
                <div>
                  <h4 className="title">Retail</h4>
                  <p className="description" align="justify">
                    Driving innovation in retail with IT solutions that enhance customer experiences, streamline operations, and boost profitability. Our services include omnichannel retail platforms, inventory management systems, and customer analytics, helping retailers stay competitive and meet evolving consumer demands.
                  </p>
                  {/* <a href="#" className="readmore stretched-link">
                    <span>Learn More</span>
                    <i className="bi bi-arrow-right" />
                  </a> */}
                </div>
              </div>

              {/* Utilities */}
              <div className="col-lg-4 col-md-6 service-item d-flex" data-aos="fade-up" data-aos-delay={900}>
                <div className="icon flex-shrink-0">
                  <i className="bi bi-lightning" style={{ color: "#56b8e6" }} />
                </div>
                <div>
                  <h4 className="title">Utilities</h4>
                  <p className="description" align="justify">
                    Transforming the utilities sector with IT solutions that enhance operational efficiency and service delivery. Our offerings include smart grid technologies, customer information systems, and asset management solutions, helping utility companies optimize their operations and provide reliable services to their customers.
                  </p>
                  {/* <a href="#" className="readmore stretched-link">
                    <span>Learn More</span>
                    <i className="bi bi-arrow-right" />
                  </a> */}
                </div>
              </div>

              {/* IT Services */}
              <div className="col-lg-4 col-md-6 service-item d-flex" data-aos="fade-up" data-aos-delay={1000}>
                <div className="icon flex-shrink-0">
                  <i className="bi bi-laptop" style={{ color: "#56b8e6" }} />
                </div>
                <div>
                  <h4 className="title">IT Services</h4>
                  <p className="description" align="justify">
                    Providing comprehensive IT services that support business growth and innovation. Our extensive range of services includes software development, IT infrastructure management, cybersecurity, and cloud solutions, ensuring your business stays ahead in a fast-paced digital world.
                  </p>
                  {/* <a href="#" className="readmore stretched-link">
                    <span>Learn More</span>
                    <i className="bi bi-arrow-right" />
                  </a> */}
                </div>
              </div>
            </div>
          </div>


        </section>
        {/* /Services Section */}
        <CallToAction />
      </main>
    </>
  );
}
