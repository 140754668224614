import ContactInfo from "./../data/contact-data.json";
import { Link } from "react-router-dom";
const TermsOfService = () => {
    const phoneOne = "tel:" + ContactInfo.phone1
    const phoneTwo = "tel:" + ContactInfo.phone2
    const emailInfo = "mailto:" + ContactInfo["email-info"]
    const emailSupport = "mailto:" + ContactInfo["email-support"]
    return (
        <main className="main">
            {/* Page Title */}
            <div
                className="page-title dark-background"
                data-aos="fade"
                style={{
                    backgroundImage: "url(assets/img/services-page-title-bg.jpg)",
                }}
            >
                <div className="container">
                    <h1>Terms of Service</h1>
                    <nav className="breadcrumbs">
                        <ol>
                            <li>
                                <Link to="/">Home</Link>
                            </li>
                            <li className="current">Terms of Service</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <section >
                <div className="container">
                    <div className="section-title" data-aos="fade-up">
                        <h2>Terms of Service</h2>
                    </div>

                    <div className="terms-content" data-aos="fade-up">
                        <h3>1. Introduction</h3>
                        <p>
                            Welcome to Global Link. These Terms of Service ("Terms") govern your use of our website and services. By accessing or using our services, you agree to comply with and be bound by these Terms. If you do not agree to these Terms, please do not use our services.
                        </p>

                        <h3>2. Definitions</h3>
                        <p>
                            <strong>"Company"</strong> refers to Global Link.<br />
                            <strong>"Services"</strong> refers to the IT consulting, big data, business intelligence, data analytics, infrastructure management, digital transformation, managed services, DevOps, CRM, and ERP services provided by the Company.<br />
                            <strong>"User"</strong> refers to any individual or entity that accesses or uses our services.
                        </p>

                        <h3>3. Use of Services</h3>
                        <h4>3.1 Eligibility</h4>
                        <p>
                            To use our services, you must be at least 18 years old and have the legal capacity to enter into a binding agreement.
                        </p>

                        <h4>3.2 User Account</h4>
                        <p>
                            You may be required to create an account to access certain services. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.
                        </p>

                        <h4>3.3 Prohibited Conduct</h4>
                        <p>
                            You agree not to use our services for any unlawful purpose or in any way that could harm the Company or any third party. Prohibited conduct includes, but is not limited to:
                            <ul>
                                <li>Violating any applicable laws or regulations.</li>
                                <li>Infringing on the rights of others.</li>
                                <li>Disrupting or interfering with the security or functionality of our services.</li>
                            </ul>
                        </p>

                        <h3>4. Intellectual Property</h3>
                        <h4>4.1 Ownership</h4>
                        <p>
                            All content and materials available on our website and through our services, including but not limited to text, graphics, logos, and software, are the property of the Company or its licensors and are protected by applicable intellectual property laws.
                        </p>

                        <h4>4.2 Limited License</h4>
                        <p>
                            We grant you a limited, non-exclusive, non-transferable license to access and use our services for personal or internal business purposes. You may not copy, modify, distribute, sell, or lease any part of our services without our prior written consent.
                        </p>

                        <h3>5. Disclaimers and Limitation of Liability</h3>
                        <h4>5.1 Disclaimers</h4>
                        <p>
                            Our services are provided "as is" and "as available" without any warranties of any kind, express or implied. We do not warrant that our services will be uninterrupted, error-free, or secure.
                        </p>

                        <h4>5.2 Limitation of Liability</h4>
                        <p>
                            To the maximum extent permitted by law, Global Link shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from:
                            <ul>
                                <li>Your use of or inability to use our services.</li>
                                <li>Any unauthorized access to or use of our servers and/or any personal information stored therein.</li>
                            </ul>
                        </p>

                        <h3>6. Indemnification</h3>
                        <p>
                            You agree to indemnify and hold harmless Global Link, its affiliates, and their respective officers, directors, employees, and agents from and against any claims, liabilities, damages, losses, and expenses, including without limitation reasonable legal and accounting fees, arising out of or in any way connected with your access to or use of our services or your violation of these Terms.
                        </p>

                        <h3>7. Termination</h3>
                        <p>
                            We may terminate or suspend your access to our services at any time, without prior notice or liability, for any reason whatsoever, including if you breach these Terms. Upon termination, your right to use our services will immediately cease.
                        </p>

                        <h3>8. Governing Law</h3>
                        <p>
                            These Terms shall be governed by and construed in accordance with the laws of the jurisdiction in which Global Link operates, without regard to its conflict of law principles.
                        </p>

                        <h3>9. Changes to Terms</h3>
                        <p>
                            We reserve the right to modify or replace these Terms at any time. If a revision is material, we will provide at least 30 days' notice prior to any new terms taking effect. Your continued use of our services after any changes to the Terms constitutes acceptance of those changes.
                        </p>

                        <h3>10. Contact Us</h3>
                        <p>
                            If you have any questions about these Terms, please contact us at:
                            <br /> <br />
                            {ContactInfo.name}<br />
                            {ContactInfo["address-plain"]}<br />
                            <a href={phoneOne}>{ContactInfo.phone1}</a><br />
                            <a href={emailInfo}>{ContactInfo["email-info"]}</a>
                        </p>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default TermsOfService;
