import ContactInfo from "./../data/contact-data.json"
import { Link, useMatch, useResolvedPath } from "react-router-dom"
import React, { useEffect, useRef, useState } from 'react';
export default function Header() {
    const [scrolled, setScrolled] = useState(false);
    const [mobileNavActive, setMobileNavActive] = useState(false);
    const scrollTopRef = useRef(null);
    const mobileNavToggleBtnRef = useRef(null);

    const handleScroll = () => {
        if (window.scrollY > 100) {
            setScrolled(true);
            scrollTopRef.current.classList.add('active');
        } else {
            setScrolled(false);
            scrollTopRef.current.classList.remove('active');
        }
    };

    const mobileNavToogle = () => {
        document.querySelector('body').classList.toggle('mobile-nav-active');
        if (mobileNavToggleBtnRef.current) {
            mobileNavToggleBtnRef.current.classList.toggle('bi-list');
            mobileNavToggleBtnRef.current.classList.toggle('bi-x');
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        if (mobileNavToggleBtnRef.current) {
            mobileNavToggleBtnRef.current.addEventListener('click', mobileNavToogle);
        }

        document.querySelectorAll('#navmenu a').forEach(navmenu => {
            navmenu.addEventListener('click', () => {
                if (document.querySelector('.mobile-nav-active')) {
                    mobileNavToogle();
                }
            });
        });

        document.querySelectorAll('.navmenu .toggle-dropdown').forEach(navmenu => {
            navmenu.addEventListener('click', function (e) {
                e.preventDefault();
                this.parentNode.classList.toggle('active');
                this.parentNode.nextElementSibling.classList.toggle('dropdown-active');
                e.stopImmediatePropagation();
            });
        });

        return () => {
            window.removeEventListener('scroll', handleScroll);
            if (mobileNavToggleBtnRef.current) {
                mobileNavToggleBtnRef.current.removeEventListener('click', mobileNavToogle);
            }
        };
    }, []);

    return (
        <>
            <header ref={scrollTopRef} id="header" className={`header d-flex align-items-center fixed-top ${scrolled ? 'scrolled' : ''}`}>
                <div className="container-fluid container-xl position-relative d-flex align-items-center justify-content-between">
                    <a href="/" className="logo d-flex align-items-center">
                        {/* Uncomment the line below if you also wish to use an image logo */}
                        <img src="assets/img/logo.png" alt="" />
                        {/* <h1 className="sitename">{ContactInfo.short}</h1> */}
                    </a>
                    <nav id="navmenu" className="navmenu">
                        <ul>
                            <li>
                                <CustomLink to="/">Home</CustomLink>
                            </li>
                            <li>
                                <CustomLink to="/about-us">About Us</CustomLink>
                            </li>
                            <li>
                                <CustomLink to="/services">Services</CustomLink>
                            </li>
                            <li>
                                <CustomLink to="/industries">Industries</CustomLink>
                            </li>
                            <li>
                                <CustomLink to="/contact-us">Contact Us</CustomLink>
                            </li>
                        </ul>
                        <i ref={mobileNavToggleBtnRef} className="mobile-nav-toggle d-xl-none bi bi-list" />
                    </nav>
                </div>
            </header>
        </>
    );
}

function CustomLink({ to, children, ...props }) {
    const resolvedPath = useResolvedPath(to)
    const isActive = useMatch({ path: resolvedPath.pathname, end: true })

    return (
        <Link to={to} {...props} className={isActive ? "nav-item active" : "nav-item"}>
            {children}
        </Link>
    )
}
