import WhoWeAre from "../components/WhoWeAre";
import CallToAction from "../components/CallToAction";
import { Link } from "react-router-dom";
export default function Home() {
    return (
        <>
            <main className="main">
                {/* Hero Section */}
                <section id="hero" className="hero section dark-background">
                    <img src="assets/img/hero-bg.jpg" alt="" data-aos="fade-in" />
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-4">
                                <h2 data-aos="fade-up">Bridging the Future with Innovation and Expertise
                                </h2>
                                <blockquote data-aos="fade-up" data-aos-delay={100}>
                                    <p align="justify">
                                        At Global Link, we transform your vision into reality through cutting-edge IT solutions and strategic consulting. Our mission is to empower businesses with the technology and insights needed to thrive in today’s dynamic digital landscape.{" "}
                                    </p>
                                </blockquote>
                                <div className="d-flex" data-aos="fade-up" data-aos-delay={200}>
                                    <Link to="/about-us" className="btn-get-started">
                                        Get Started
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* /Hero Section */}
                {/* Why Us Section */}
                <WhoWeAre />

                {/* /Why Us Section */}
                {/* Services Section */}
                <section id="services" className="services section">
                    {/* Section Title */}
                    <div className="container section-title" data-aos="fade-up">
                        <h2>Our Services</h2>
                        <p>
                            At Global Link, we offer a comprehensive range of IT services designed to meet the unique needs of your business. Our solutions are tailored to drive innovation, enhance efficiency, and create lasting value. Explore our diverse service offerings below:
                        </p>
                    </div>
                    {/* End Section Title */}
                    <div className="container">
                        <div className="row gy-4">
                            {/* IT Consulting */}
                            <div className="col-lg-4 col-md-6 service-item d-flex" data-aos="fade-up" data-aos-delay={100}>
                                <div className="icon flex-shrink-0">
                                    <i className="bi bi-briefcase" style={{ color: "#1335f5" }} />
                                </div>
                                <div>
                                    <h4 className="title">IT Consulting</h4>
                                    <p className="description" align="justify">
                                        Our IT consulting services provide comprehensive guidance to navigate the digital landscape. We develop tailored IT strategies, integrate cutting-edge systems, and optimize your technology investments. Our experts work closely with you to align IT initiatives with your business goals, ensuring seamless implementation and long-term success.
                                    </p>
                                    {/* <a href="#" className="readmore stretched-link">
                    <span>Learn More</span>
                    <i className="bi bi-arrow-right" />
                  </a> */}
                                </div>
                            </div>

                            {/* Big Data */}
                            <div className="col-lg-4 col-md-6 service-item d-flex" data-aos="fade-up" data-aos-delay={200}>
                                <div className="icon flex-shrink-0">
                                    <i className="bi bi-bar-chart" style={{ color: "#1335f5" }} />
                                </div>
                                <div>
                                    <h4 className="title">Big Data</h4>
                                    <p className="description" align="justify">
                                        Unlock the power of vast data sets with our Big Data solutions. We help you store, process, and analyze large volumes of data using advanced technologies like Hadoop, Spark, and NoSQL databases. Transform raw data into valuable insights to drive strategic decisions, improve operations, and gain a competitive edge.
                                    </p>
                                    {/* <a href="#" className="readmore stretched-link">
                    <span>Learn More</span>
                    <i className="bi bi-arrow-right" />
                  </a> */}
                                </div>
                            </div>

                            {/* Business Intelligence */}
                            <div className="col-lg-4 col-md-6 service-item d-flex" data-aos="fade-up" data-aos-delay={300}>
                                <div className="icon flex-shrink-0">
                                    <i className="bi bi-graph-up" style={{ color: "#1335f5" }} />
                                </div>
                                <div>
                                    <h4 className="title">Business Intelligence</h4>
                                    <p className="description" align="justify">
                                        Transform your data into actionable insights with our Business Intelligence services. Utilizing tools like Power BI, Tableau, and QlikView, we create intuitive dashboards and reports. Monitor key performance indicators, identify trends, and make data-driven decisions that propel your business forward.
                                    </p>
                                    {/* <a href="#" className="readmore stretched-link">
                    <span>Learn More</span>
                    <i className="bi bi-arrow-right" />
                  </a> */}
                                </div>
                            </div>

                            {/* Data Analytics */}
                            <div className="col-lg-4 col-md-6 service-item d-flex" data-aos="fade-up" data-aos-delay={400}>
                                <div className="icon flex-shrink-0">
                                    <i className="bi bi-pie-chart" style={{ color: "#1335f5" }} />
                                </div>
                                <div>
                                    <h4 className="title">Data Analytics</h4>
                                    <p className="description" align="justify">
                                        Gain deeper insights into your business with our data analytics services. We offer descriptive, predictive, and prescriptive analytics using advanced statistical methods and machine learning. Understand past performance, forecast future trends, and optimize decision-making to drive success.
                                    </p>
                                    {/* <a href="#" className="readmore stretched-link">
                    <span>Learn More</span>
                    <i className="bi bi-arrow-right" />
                  </a> */}
                                </div>
                            </div>

                            {/* Infrastructure Management */}
                            <div className="col-lg-4 col-md-6 service-item d-flex" data-aos="fade-up" data-aos-delay={500}>
                                <div className="icon flex-shrink-0">
                                    <i className="bi bi-server" style={{ color: "#1335f5" }} />
                                </div>
                                <div>
                                    <h4 className="title">Infrastructure Management</h4>
                                    <p className="description" align="justify">
                                        Ensure the seamless operation of your IT infrastructure with our management services. We cover network management, server maintenance, cloud solutions, and disaster recovery. Our proactive approach minimizes downtime, optimizes performance, and protects your infrastructure from threats.
                                    </p>
                                    {/* <a href="#" className="readmore stretched-link">
                    <span>Learn More</span>
                    <i className="bi bi-arrow-right" />
                  </a> */}
                                </div>
                            </div>

                            {/* Digital Transformation */}
                            <div className="col-lg-4 col-md-6 service-item d-flex" data-aos="fade-up" data-aos-delay={600}>
                                <div className="icon flex-shrink-0">
                                    <i className="bi bi-lightbulb" style={{ color: "#1335f5" }} />
                                </div>
                                <div>
                                    <h4 className="title">Digital Transformation</h4>
                                    <p className="description" align="justify">
                                        Modernize your business with our digital transformation services. We help you adopt new technologies, automate workflows, and create a digital-first culture. Enhance customer experiences, improve operational efficiency, and drive innovation with our tailored solutions.
                                    </p>
                                    {/* <a href="#" className="readmore stretched-link">
                    <span>Learn More</span>
                    <i className="bi bi-arrow-right" />
                  </a> */}
                                </div>
                            </div>

                            {/* Managed Services */}
                            <div className="col-lg-4 col-md-6 service-item d-flex" data-aos="fade-up" data-aos-delay={700}>
                                <div className="icon flex-shrink-0">
                                    <i className="bi bi-gear" style={{ color: "#1335f5" }} />
                                </div>
                                <div>
                                    <h4 className="title">Managed Services</h4>
                                    <p className="description" align="justify">
                                        Focus on your core business while we handle your IT operations. Our managed services include 24/7 monitoring, IT support, patch management, and backup and recovery. We ensure uninterrupted business continuity and optimal performance of your IT systems, reducing stress and controlling costs.
                                    </p>
                                    {/* <a href="#" className="readmore stretched-link">
                    <span>Learn More</span>
                    <i className="bi bi-arrow-right" />
                  </a> */}
                                </div>
                            </div>

                            {/* DevOps */}
                            <div className="col-lg-4 col-md-6 service-item d-flex" data-aos="fade-up" data-aos-delay={800}>
                                <div className="icon flex-shrink-0">
                                    <i className="bi bi-code-slash" style={{ color: "#1335f5" }} />
                                </div>
                                <div>
                                    <h4 className="title">DevOps</h4>
                                    <p className="description" align="justify">
                                        Accelerate your software development lifecycle with our DevOps solutions. We offer continuous integration, deployment, and automated testing. Enhance collaboration between development and operations teams, delivering software faster and with higher quality using tools like Jenkins, Git, Docker, and Kubernetes.
                                    </p>
                                    {/* <a href="#" className="readmore stretched-link">
                    <span>Learn More</span>
                    <i className="bi bi-arrow-right" />
                  </a> */}
                                </div>
                            </div>

                            {/* CRM */}
                            <div className="col-lg-4 col-md-6 service-item d-flex" data-aos="fade-up" data-aos-delay={900}>
                                <div className="icon flex-shrink-0">
                                    <i className="bi bi-people" style={{ color: "#1335f5" }} />
                                </div>
                                <div>
                                    <h4 className="title">CRM</h4>
                                    <p className="description" align="justify">
                                        Enhance your customer relationships with our CRM solutions. We provide customized services to manage interactions, improve sales processes, and deliver personalized experiences. Utilize platforms like Salesforce, Microsoft Dynamics, and HubSpot to boost customer satisfaction, loyalty, and lifetime value.
                                    </p>
                                    {/* <a href="#" className="readmore stretched-link">
                    <span>Learn More</span>
                    <i className="bi bi-arrow-right" />
                  </a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* /Services Section */}
                <CallToAction />
                {/* Features Section */}
                <section id="features" className="features section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7" data-aos="fade-up" data-aos-delay={100}>
                                <h3 className="mb-0">Industry Leading Managed</h3>
                                <h3>Services & Consulting Solutions</h3>
                                <div className="row gy-4">
                                    <div className="col-md-6">
                                        <div className="icon-list d-flex">
                                            <i className="bi bi-eye" style={{ color: "#ff8b2c" }} />
                                            <span>Digital transformation consulting</span>
                                        </div>
                                    </div>
                                    {/* End Icon List Item*/}
                                    <div className="col-md-6">
                                        <div className="icon-list d-flex">
                                            <i className="bi bi-infinity" style={{ color: "#5578ff" }} />
                                            <span>IT strategy consulting</span>
                                        </div>
                                    </div>
                                    {/* End Icon List Item*/}
                                    <div className="col-md-6">
                                        <div className="icon-list d-flex">
                                            <i className="bi bi-mortarboard" style={{ color: "#e80368" }} />
                                            <span>Application modernization </span>
                                        </div>
                                    </div>
                                    {/* End Icon List Item*/}
                                    <div className="col-md-6">
                                        <div className="icon-list d-flex">
                                            <i className="bi bi-star" style={{ color: "#ffa76e" }} />
                                            <span>IT assessment & Technology consulting</span>
                                        </div>
                                    </div>
                                    {/* End Icon List Item*/}
                                    <div className="col-md-6">
                                        <div className="icon-list d-flex">
                                            <i className="bi bi-x-diamond" style={{ color: "#11dbcf" }} />
                                            <span>IT operations consulting</span>
                                        </div>
                                    </div>
                                    {/* End Icon List Item*/}
                                    <div className="col-md-6">
                                        <div className="icon-list d-flex">
                                            <i
                                                className="bi bi-camera-video"
                                                style={{ color: "#4233ff" }}
                                            />
                                            <span>IT project & program management</span>
                                        </div>
                                    </div>
                                    {/* End Icon List Item*/}
                                    <div className="col-md-6">
                                        <div className="icon-list d-flex">
                                            <i
                                                className="bi bi-brightness-high"
                                                style={{ color: "#29cc61" }}
                                            />
                                            <span>Application implementation</span>
                                        </div>
                                    </div>
                                    {/* End Icon List Item*/}
                                    <div className="col-md-6">
                                        <div className="icon-list d-flex">
                                            <i className="bi bi-activity" style={{ color: "#ff5828" }} />
                                            <span>Application support</span>
                                        </div>
                                    </div>
                                    {/* End Icon List Item*/}
                                </div>
                            </div>
                            <div
                                className="col-lg-5 position-relative"
                                data-aos="zoom-out"
                                data-aos-delay={200}
                            >
                                <div className="phone-wrap">
                                    <img
                                        src="assets/img/home-imge.jpg"
                                        alt="Image"
                                        className="img-fluid"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="details">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6" data-aos="fade-up" data-aos-delay={300}>
                                    <p>
                                    Our comprehensive range of services encompasses the entire spectrum of IT, spanning from strategic planning and meticulous execution to ongoing support and maintenance. By immersing ourselves in our clients' operations, we forge a deep understanding of their unique business needs, enabling us to craft tailored solutions that seamlessly blend innovation with practicality.
                                    </p>
                                    <Link to="/services" className="btn-get-started">
                                        Get Started
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* /Features Section */}
                
                <section id="blog-posts" className="blog-posts section">
                    {/* Section Title */}
                    <div className="container section-title" data-aos="fade-up">
                        <h2>Our Work</h2>
                        <p>
                        We’ve Done Lot’s of Work, Let’s Check Some From Here.<br/>
                        We have the best experts to elevate your business to the next level, try is and you will see!
                        </p>
                    </div>
                    {/* End Section Title */}
                    <div className="container">
                        <div className="row gy-4">
                            <div className="col-lg-3">
                                <article>
                                    <div className="post-img">
                                        <img
                                            src="assets/img/industries-small/automotive.png"
                                            alt=""
                                            className="img-fluid"
                                        />
                                    </div>
                                    <p className="post-category">Automotive</p>

                                </article>
                            </div>
                            {/* End post list item */}
                            <div className="col-lg-3">
                                <article>
                                    <div className="post-img">
                                        <img
                                            src="assets/img/industries-small/banking.png"
                                            alt=""
                                            className="img-fluid"
                                        />
                                    </div>
                                    <p className="post-category">Banking</p>

                                </article>
                            </div>
                            {/* End post list item */}
                            <div className="col-lg-3">
                                <article>
                                    <div className="post-img">
                                        <img
                                            src="assets/img/industries-small/communications.png"
                                            alt=""
                                            className="img-fluid"
                                        />
                                    </div>
                                    <p className="post-category">Communications</p>
                                </article>
                            </div>
                            {/* End post list item */}
                            <div className="col-lg-3">
                                <article>
                                    <div className="post-img">
                                        <img
                                            src="assets/img/industries-small/insurance.png"
                                            alt=""
                                            className="img-fluid"
                                        />
                                    </div>
                                    <p className="post-category">Insurance</p>
                                </article>
                            </div>
                            
                            {/* End post list item */}
                            <div className="col-lg-3">
                                <article>
                                    <div className="post-img">
                                        <img
                                            src="assets/img/services-small/data-analytics.png"
                                            alt=""
                                            className="img-fluid"
                                        />
                                    </div>
                                    <p className="post-category">Data Analytics</p>


                                </article>
                            </div>
                            {/* End post list item */}
                            <div className="col-lg-3">
                                <article>
                                    <div className="post-img">
                                        <img
                                            src="assets/img/services-small/devops.png"
                                            alt=""
                                            className="img-fluid"
                                        />
                                    </div>
                                    <p className="post-category">DevOps</p>


                                </article>
                            </div>
                            {/* End post list item */}
                            <div className="col-lg-3">
                                <article>
                                    <div className="post-img">
                                        <img
                                            src="assets/img/services-small/it-consulting.png"
                                            alt=""
                                            className="img-fluid"
                                        />
                                    </div>
                                    <p className="post-category">IT Consulting</p>

                                </article>
                            </div>
                            {/* End post list item */}
                            <div className="col-lg-3">
                                <article>
                                    <div className="post-img">
                                        <img
                                            src="assets/img/services-small/business-intelligence.png"
                                            alt=""
                                            className="img-fluid"
                                        />
                                    </div>
                                    <p className="post-category">Business Intelligence</p>
                                </article>
                            </div>
                            {/* End post list item */}
                        </div>
                    </div>
                </section>
                {/* /Blog Posts Section */}
            </main>

        </>
    );
}
