import ContactInfo from "./../data/contact-data.json";
import { Link } from "react-router-dom";
const PrivacyPolicy = () => {
    const phoneOne = "tel:" + ContactInfo.phone1
    const phoneTwo = "tel:" + ContactInfo.phone2
    const emailInfo = "mailto:" + ContactInfo["email-info"]
    const emailSupport = "mailto:" + ContactInfo["email-support"]
    return (
        <main className="main">
            {/* Page Title */}
            <div
                className="page-title dark-background"
                data-aos="fade"
                style={{
                    backgroundImage: "url(assets/img/services-page-title-bg.jpg)",
                }}
            >
                <div className="container">
                    <h1>Terms of Service</h1>
                    <nav className="breadcrumbs">
                        <ol>
                            <li>
                                <Link to="/">Home</Link>
                            </li>
                            <li className="current">Terms of Service</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <section >
                <div className="container">
                    <div className="section-title" data-aos="fade-up">
                        <h2>Privacy Policy</h2>
                    </div>

                    <div className="privacy-content" data-aos="fade-up">
                        <h3>1. Introduction</h3>
                        <p>
                            Welcome to Global Link. We are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This Privacy Policy outlines how we collect, use, disclose, and protect your information.
                        </p>

                        <h3>2. Information We Collect</h3>
                        <p>
                            We collect various types of information in connection with the services we provide, including:
                            <ul>
                                <li><strong>Personal Information:</strong> Name, email address, phone number, and other contact details.</li>
                                <li><strong>Technical Information:</strong> IP address, browser type, operating system, and other technical information about your device and usage.</li>
                                <li><strong>Usage Information:</strong> Information about how you use our services, including pages visited, time spent on pages, and other usage statistics.</li>
                                <li><strong>Cookies:</strong> Small data files stored on your device that help us understand how you interact with our website.</li>
                            </ul>
                        </p>

                        <h3>3. How We Use Your Information</h3>
                        <p>
                            We use the information we collect to:
                            <ul>
                                <li>Provide, maintain, and improve our services.</li>
                                <li>Communicate with you, including responding to your inquiries and providing updates and information about our services.</li>
                                <li>Analyze usage patterns and improve our website and services.</li>
                                <li>Ensure the security and integrity of our services.</li>
                                <li>Comply with legal obligations and enforce our terms and policies.</li>
                            </ul>
                        </p>

                        <h3>4. Sharing Your Information</h3>
                        <p>
                            We do not sell your personal information. We may share your information with:
                            <ul>
                                <li><strong>Service Providers:</strong> Third-party vendors and service providers who assist us in providing our services.</li>
                                <li><strong>Legal Obligations:</strong> When required by law or to protect our rights, property, or safety, or the rights, property, or safety of others.</li>
                                <li><strong>Business Transfers:</strong> In connection with any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</li>
                            </ul>
                        </p>

                        <h3>5. Security</h3>
                        <p>
                            We implement reasonable security measures to protect your information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.
                        </p>

                        <h3>6. Your Rights</h3>
                        <p>
                            Depending on your location, you may have certain rights regarding your personal information, including:
                            <ul>
                                <li>The right to access and obtain a copy of your personal information.</li>
                                <li>The right to request correction of any inaccurate or incomplete personal information.</li>
                                <li>The right to request deletion of your personal information.</li>
                                <li>The right to object to or restrict the processing of your personal information.</li>
                                <li>The right to withdraw consent, if processing is based on consent.</li>
                            </ul>
                            To exercise these rights, please contact us using the information provided below.
                        </p>

                        <h3>7. Cookies</h3>
                        <p>
                            We use cookies to enhance your experience on our website. You can control the use of cookies through your browser settings. However, if you choose to disable cookies, some features of our website may not function properly.
                        </p>

                        <h3>8. Changes to This Privacy Policy</h3>
                        <p>
                            We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website. You are advised to review this Privacy Policy periodically for any changes.
                        </p>

                        <h3>9. Contact Us</h3>
                        <p>
                            If you have any questions about this Privacy Policy, please contact us at:
                            <br /> <br />
                            {ContactInfo.name}<br />
                            {ContactInfo["address-plain"]}<br />
                            <a href={phoneOne}>{ContactInfo.phone1}</a><br />
                            <a href={emailInfo}>{ContactInfo["email-info"]}</a>
                        </p>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default PrivacyPolicy;
