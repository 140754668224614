import React, { useEffect, useRef } from 'react';
import { Swiper as SwiperOne } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay, Pagination, Navigation } from "swiper/modules";
// import './../assets/vendor/swiper/swiper-bundle.min.css';
import 'swiper/css';
import 'swiper/css/bundle';
const WhoWeAre = () => {
    return (
        <>
            <section id="why-us" className="why-us section">
                <div className="container">
                    <div className="row g-0">
                        <div
                            className="col-xl-5 img-bg"
                            data-aos="fade-up"
                            data-aos-delay={100}
                        >
                            <img src="assets/img/why-us-bg.jpg" alt="" />
                        </div>
                        <div
                            className="col-xl-7 slides position-relative"
                            data-aos="fade-up"
                            data-aos-delay={200}
                        >
                            <Swiper
                                spaceBetween={500}
                                centeredSlides={true}
                                autoplay={{
                                    delay: 5000,
                                    disableOnInteraction: false,
                                }}
                                pagination={{
                                    clickable: true,
                                }}
                                // navigation={true}
                                modules={[Autoplay, Pagination, Navigation]}
                                className="mySwiper"
                            >
                                <SwiperSlide>
                                    <div className="swiper-slide">
                                        <div className="item">
                                            <h3 className="mb-3">Why Choose Us ? </h3>
                                            <p align="justify">
                                            Global Link is a newly launched IT services and consulting company, committed to delivering high-quality, tailored solutions to meet the unique needs of our clients. We are passionate about helping businesses thrive in today’s dynamic digital landscape by providing innovative and effective IT services.
                                            </p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-slide">
                                        <div className="item">
                                            <h3 className="mb-3">Who We Are ??</h3>
                                            <p align="justify">Our team is composed of seasoned professionals who bring a wealth of experience and expertise to the table. We are dedicated to staying ahead of industry trends and continually enhancing our skills to offer the best possible solutions to our clients.</p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-slide">
                                        <div className="item">
                                            <h3 className="mb-3">Who We Are ??</h3>
                                            <p align="justify">At Global Link, we believe in a client-centric approach. We work closely with our clients to understand their specific challenges and goals, ensuring that our solutions are perfectly aligned with their business objectives. This collaborative effort ensures successful outcomes and long-lasting relationships.</p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-slide">
                                        <div className="item">
                                            <h3 className="mb-3">Who We Are ??</h3>
                                            <p align="justify">Despite being new in the market, our collective expertise and successful project history speak volumes about our capabilities. We are committed to upholding the highest standards of quality and integrity in all our services, ensuring exceptional results for our clients.</p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default WhoWeAre;
