import ContactInfo from './../data/contact-data.json';
export default function CallToAction() {
    const ctaPhone = "tel:"+ContactInfo.phone1

    return (
        <>
            {/* Call To Action Section */}
            <section
                id="call-to-action"
                className="call-to-action section dark-background"
            >
                <img src="assets/img/cta-bg.jpg" alt="" />
                <div className="container">
                    <div
                        className="row justify-content-center"
                        data-aos="zoom-in"
                        data-aos-delay={100}
                    >
                        <div className="col-xl-10">
                            <div className="text-center">
                                <h3>Call To Action</h3>
                                <p>
                                Ready to take your business to the next level? Contact us today to learn how Global Link can help you achieve your IT and business goals.</p>
                                <a className="cta-btn" href={ctaPhone}>
                                    Call To Action
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* /Call To Action Section */}
        </>
    );
}