import logo from './logo.svg';
// import css 
import './assets/vendor/bootstrap/css/bootstrap.min.css';
import './assets/vendor/bootstrap-icons/bootstrap-icons.css';
import './assets/vendor/aos/aos.css';
import Aos from 'aos';
import GLightbox from 'glightbox';
import './assets/vendor/glightbox/css/glightbox.min.css';
// import './assets/vendor/swiper/swiper-bundle.min.css';
import './assets/css/main.css'
// import Pages and Components 
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Industries from './pages/Industries';
import Portfolio from './pages/Portfolio';
import Contact from './pages/Contact';
import TermsOfService from './pages/TermsOfService';
import PrivacyPolicy from './pages/PrivacyPolicy';
import PageNotFound from './pages/PageNotFound';
import Header from './components/Header';
import Footer from './components/Footer';
import Preloader from './components/PreLoader';
import React, { useEffect, Component } from 'react';
import { Route, Link, Routes, BrowserRouter,} from "react-router-dom";
function App() {
  useEffect(() => {
    Aos.init();
    function toggleScrolled() {
      const selectBody = document.querySelector('body');
      const selectHeader = document.querySelector('#header');
      if (!selectHeader.classList.contains('scroll-up-sticky') && !selectHeader.classList.contains('sticky-top') && !selectHeader.classList.contains('fixed-top')) return;
      window.scrollY > 100 ? selectBody.classList.add('scrolled') : selectBody.classList.remove('scrolled');
    }

    document.addEventListener('scroll', toggleScrolled);
    window.addEventListener('load', toggleScrolled);
  
    let scrollTop = document.querySelector('.scroll-top');

    function toggleScrollTop() {
      if (scrollTop) {
        window.scrollY > 100 ? scrollTop.classList.add('active') : scrollTop.classList.remove('active');
      }
    }
    scrollTop.addEventListener('click', (e) => {
      e.preventDefault();
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    });

    window.addEventListener('load', toggleScrollTop);
    document.addEventListener('scroll', toggleScrollTop);


    Aos.init({
      duration: 600,
      easing: 'ease-in-out',
      once: true,
      mirror: false
    });
    GLightbox({
      selector: '.glightbox'
    });
  }, [])
  return (
    <>
      <BrowserRouter>
        <Header />
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about-us" element={<About />} />
            <Route path="/services" element={<Services />} />
            <Route path="/industries" element={<Industries />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/contact-us" element={<Contact />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            {/* <Route path="/terms-of-use" element={<TermsOfUse />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/it-consulting" element={<ITConsulting />} */}
            <Route path="*" element={< PageNotFound />} />
        </Routes>
        <Footer />
      </BrowserRouter>      
    </>
  );
}

export default App;
