import ContactInfo from "./../data/contact-data.json"
import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
export default function Footer() {
    const phoneOne = "tel:" + ContactInfo.phone1
    const phoneTwo = "tel:" + ContactInfo.phone2
    const emailInfo = "mailto:" + ContactInfo["email-info"]
    const emailSupport = "mailto:" + ContactInfo["email-support"]
    const social = ContactInfo.social

    // useEffect(() => {
    //     const handleLoad = () => {
    //         const preloader = document.querySelector('#preloader');
    //         if (preloader) {

    //             preloader.remove();
    //         }
    //     };

    //     window.addEventListener('load', handleLoad);

    //     return () => {
    //         window.removeEventListener('load', handleLoad);
    //     };
    // }, []);
    return (
        <>
            <footer id="footer" className="footer light-background">
                <div className="footer-top">
                    <div className="container">
                        <div className="row gy-4">
                            <div className="col-lg-5 col-md-12 footer-about">
                                <Link to="/" className="logo d-flex align-items-center">
                                    <span className="sitename">{ContactInfo.short}</span>
                                </Link>
                                <p align="justify">
                                    At Global Link, we transform your vision into reality through cutting-edge IT solutions and strategic consulting. Our mission is to empower businesses with the technology and insights needed to thrive in today’s dynamic digital landscape.
                                </p>
                                <div className="social-links d-flex mt-4">
                                    <a href="">
                                        <i className="bi bi-twitter-x" />
                                    </a>
                                    <a href="">
                                        <i className="bi bi-facebook" />
                                    </a>
                                    <a href="">
                                        <i className="bi bi-instagram" />
                                    </a>
                                    <a href="">
                                        <i className="bi bi-linkedin" />
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-3 col-6 footer-links">
                                <h4>Useful Links</h4>
                                <ul>
                                    <li>
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li>
                                        <Link to="/about-us">About Us</Link>
                                    </li>
                                    <li>
                                        <Link to="/services">Services</Link>
                                    </li>
                                    <li>
                                        <Link to="/industries">Industries</Link>
                                    </li>
                                    <li>
                                        <Link to="/contact-us">Contact Us</Link>
                                    </li>
                                </ul>
                            </div>
                            {/* <div className="col-lg-2 col-6 footer-links">
                                <h4>Our Services</h4>
                                <ul>
                                    <li><Link to="#">IT Consulting  </Link></li>
                                    <li><Link to="#">Big Data</Link></li>
                                    <li><Link to="#">Business Intelligence</Link></li>
                                    <li><Link to="#">Data Analytics</Link></li>
                                    <li><Link to="#">Infrastructure Management</Link></li>
                                    <li><Link to="#">Digital Transformation</Link></li>
                                    <li><Link to="#">Managed Services</Link></li>
                                    <li><Link to="#">DevOps</Link></li>
                                    <li><Link to="#">CRM</Link></li>
                                    <li><Link to="#">ERP</Link></li>
                                </ul>
                            </div>
                            <div className="col-lg-2 col-6 footer-links">
                                <h4>Our Services</h4>
                                <ul>
                                    <li>
                                        <Link to="">Web Design</Link>
                                    </li>
                                    <li>
                                        <Link to="">Web Development</Link>
                                    </li>
                                    <li>
                                        <Link to="">Product Management</Link>
                                    </li>
                                    <li>
                                        <Link to="">Marketing</Link>
                                    </li>
                                    <li>
                                        <Link to="">Graphic Design</Link>
                                    </li>
                                </ul>
                            </div> */}
                            <div className="col-lg-4 col-md-12 footer-contact text-center text-md-start address-link">
                                <h4>Contact Us</h4>
                                <p className="mt-3">
                                    <span> <a href="#">
                                    <table>
                                        <tr><td><i class="bi bi-pin-map-fill"> </i></td><td><p dangerouslySetInnerHTML={{__html: ContactInfo.address}} /></td></tr>
                                    </table>
                                         </a>
                                    </span>
                                </p>
                                <p className="mt-3">
                                    <span><a href={phoneOne}> <i class="bi bi-telephone-fill"> </i> 
                                        {ContactInfo.phone1}
                                    </a></span>
                                </p>
                                <p className="mt-3">
                                    <span><a href={phoneOne}> <i class="bi bi-envelope-at-fill"> </i>
                                        {ContactInfo["email-info"]}
                                    </a></span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container copyright">
                    <div className="row">
                        <div className="col-md-6 col-lg-6 col-sm-12">
                            <p>
                                © <span>Copyright {(new Date().getFullYear())}.</span> <strong className="px-1 sitename">Global Link</strong>{" "}
                                <span>All Rights Reserved.</span>  <span>Developed by <a target="_blank" href="https://witinventions.com/">WIT Inventions</a></span>
                            </p>
                        </div>
                        <div className="col-md-6 col-lg-6 col-sm-12 d-flex justify-content-end">
                            <Link to="/terms-of-service">Terms of service </Link> &nbsp; &nbsp; &nbsp;
                            <Link to="/privacy-policy"> Privacy policy</Link>
                        </div>
                    </div>
                    
                </div>
            </footer>
            {/* Scroll Top */}
            <a
                href="#"
                id="scroll-top"
                className="scroll-top d-flex align-items-center justify-content-center"
            >
                <i className="bi bi-arrow-up-short" />
            </a>
            {/* Preloader */}
            

        </>
    );
}

