import { Link } from "react-router-dom";
import CallToAction from "../components/CallToAction";
import WhoWeAre from "../components/WhoWeAre";

export default function About() {
    return (
        <>
            <main className="main">
                {/* Page Title */}
                <div
                    className="page-title dark-background"
                    data-aos="fade"
                    style={{ backgroundImage: "url(assets/img/about-page-title-bg.jpg)" }}
                >
                    <div className="container">
                        <h1>About</h1>
                        <nav className="breadcrumbs">
                            <ol>
                                <li>
                                    <Link to="/">Home</Link>
                                </li>
                                <li className="current">About</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                {/* End Page Title */}
                {/* About Section */}
                <section id="about" className="about section">
                    <div className="container">
                        <div className="row gy-4" data-aos="fade-up" data-aos-delay={100}>
                            <div className="col-lg-5">
                                <img src="assets/img/about.jpg" className="img-fluid" alt="" />
                            </div>
                            <div className="col-lg-7" data-aos="fade-up" data-aos-delay={200}>
                                <div className="content">
                                    <h3>Our Story</h3>
                                    <p align="justify">Global Link was founded with a vision to bridge the gap between technology and business needs. Recognizing the rapid pace of technological advancements and the growing demand for innovative solutions, we set out to create a company that not only meets these demands but anticipates them. Our journey began with a small team of dedicated professionals who shared a common goal: to transform the way businesses operate through the power of IT.</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="about" className="about section">
                    <div className="container">
                        <div className="row gy-4" data-aos="fade-up" data-aos-delay={100}>
                            <div className="col-lg-7" data-aos="fade-up" data-aos-delay={200}>
                                <div className="content">
                                    <h3>Our Mission</h3>
                                    <p align="justify">Our mission at Global Link is to empower businesses with the technology and insights they need to succeed in today’s competitive landscape. We are committed to providing high-quality IT services and strategic consulting that drive innovation, enhance efficiency, and create value for our clients.</p>
                                    
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <img src="assets/img/about.jpg" className="img-fluid" alt="" />
                            </div>
                            
                        </div>
                    </div>
                </section>
                <section id="about" className="about section">
                    <div className="container">
                        <div className="row gy-4" data-aos="fade-up" data-aos-delay={100}>
                            <div className="col-lg-5">
                                <img src="assets/img/about.jpg" className="img-fluid" alt="" />
                            </div>
                            <div className="col-lg-7" data-aos="fade-up" data-aos-delay={200}>
                                <div className="content">
                                    <h3>Our Vision</h3>
                                    <p align="justify">We envision a future where technology seamlessly integrates with business processes, enabling companies to achieve unprecedented levels of success. At Global Link, we strive to be at the forefront of this transformation, constantly pushing the boundaries of what’s possible with IT solutions.</p>
                                    <h3>Our Values</h3>
                                    <ul>
                                        <li>
                                            <i className="bi bi-check-circle-fill" />{" "}
                                            <span>
                                                <strong>Innovation:</strong>We embrace change and are always looking for new and better ways to serve our clients. Innovation is at the heart of everything we do.
                                            </span>
                                        </li>
                                        <li>
                                            <i className="bi bi-check-circle-fill" />{" "}
                                            <span>
                                                <strong>Excellence:</strong>We are committed to delivering the highest quality services and solutions. Excellence is our standard, and we work tirelessly to meet and exceed it.
                                            </span>
                                        </li>
                                        <li>
                                            <i className="bi bi-check-circle-fill" />{" "}
                                            <span><strong>Integrity:</strong> We believe in doing the right thing, always. Integrity guides our actions and decisions, ensuring that we build trust and long-lasting relationships with our clients.</span>
                                        </li>
                                        <li>
                                            <i className="bi bi-check-circle-fill" />{" "}
                                            <span><strong>Collaboration:</strong> We value teamwork and believe that the best solutions come from working together. Collaboration with our clients and within our team is essential to our success.</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* /About Section */}
                {/* Why Us Section */}
                <WhoWeAre />
                {/* /Why Us Section */}
                {/* Call To Action Section */}
                <CallToAction />
                {/* /Call To Action Section */}
                {/* Team Section */}
                <section id="team" className="team section">
                    {/* Section Title */}
                    <div className="container section-title" data-aos="fade-up">
                        <h2>Our Team</h2>
                        <p>
                        Our team is composed of highly skilled professionals with diverse backgrounds and extensive experience in IT and consulting. Each member brings a unique set of skills and perspectives, allowing us to offer a comprehensive range of services. We are passionate about what we do and are dedicated to helping our clients achieve their goals.
                        </p>
                    </div>
                    {/* End Section Title */}
                    <div className="container">
                        <div className="row gy-4">
                            <div
                                className="col-lg-3 col-md-6 d-flex align-items-stretch"
                                data-aos="fade-up"
                                data-aos-delay={100}
                            >
                                <div className="team-member">
                                    <div className="member-img">
                                        <img
                                            src="assets/img/team/team-1.jpg"
                                            className="img-fluid"
                                            alt=""
                                        />
                                        <div className="social">
                                            <a href="">
                                                <i className="bi bi-twitter-x" />
                                            </a>
                                            <a href="">
                                                <i className="bi bi-facebook" />
                                            </a>
                                            <a href="">
                                                <i className="bi bi-instagram" />
                                            </a>
                                            <a href="">
                                                <i className="bi bi-linkedin" />
                                            </a>
                                        </div>
                                    </div>
                                    <div className="member-info">
                                        <h4>Walter White</h4>
                                        <span>Chief Executive Officer</span>
                                    </div>
                                </div>
                            </div>
                            {/* End Team Member */}
                            <div
                                className="col-lg-3 col-md-6 d-flex align-items-stretch"
                                data-aos="fade-up"
                                data-aos-delay={200}
                            >
                                <div className="team-member">
                                    <div className="member-img">
                                        <img
                                            src="assets/img/team/team-2.jpg"
                                            className="img-fluid"
                                            alt=""
                                        />
                                        <div className="social">
                                            <a href="">
                                                <i className="bi bi-twitter-x" />
                                            </a>
                                            <a href="">
                                                <i className="bi bi-facebook" />
                                            </a>
                                            <a href="">
                                                <i className="bi bi-instagram" />
                                            </a>
                                            <a href="">
                                                <i className="bi bi-linkedin" />
                                            </a>
                                        </div>
                                    </div>
                                    <div className="member-info">
                                        <h4>Sarah Jhonson</h4>
                                        <span>Product Manager</span>
                                    </div>
                                </div>
                            </div>
                            {/* End Team Member */}
                            <div
                                className="col-lg-3 col-md-6 d-flex align-items-stretch"
                                data-aos="fade-up"
                                data-aos-delay={300}
                            >
                                <div className="team-member">
                                    <div className="member-img">
                                        <img
                                            src="assets/img/team/team-3.jpg"
                                            className="img-fluid"
                                            alt=""
                                        />
                                        <div className="social">
                                            <a href="">
                                                <i className="bi bi-twitter-x" />
                                            </a>
                                            <a href="">
                                                <i className="bi bi-facebook" />
                                            </a>
                                            <a href="">
                                                <i className="bi bi-instagram" />
                                            </a>
                                            <a href="">
                                                <i className="bi bi-linkedin" />
                                            </a>
                                        </div>
                                    </div>
                                    <div className="member-info">
                                        <h4>William Anderson</h4>
                                        <span>CTO</span>
                                    </div>
                                </div>
                            </div>
                            {/* End Team Member */}
                            <div
                                className="col-lg-3 col-md-6 d-flex align-items-stretch"
                                data-aos="fade-up"
                                data-aos-delay={400}
                            >
                                <div className="team-member">
                                    <div className="member-img">
                                        <img
                                            src="assets/img/team/team-4.jpg"
                                            className="img-fluid"
                                            alt=""
                                        />
                                        <div className="social">
                                            <a href="">
                                                <i className="bi bi-twitter-x" />
                                            </a>
                                            <a href="">
                                                <i className="bi bi-facebook" />
                                            </a>
                                            <a href="">
                                                <i className="bi bi-instagram" />
                                            </a>
                                            <a href="">
                                                <i className="bi bi-linkedin" />
                                            </a>
                                        </div>
                                    </div>
                                    <div className="member-info">
                                        <h4>Amanda Jepson</h4>
                                        <span>Accountant</span>
                                    </div>
                                </div>
                            </div>
                            {/* End Team Member */}
                        </div>
                    </div>
                </section>
                {/* /Team Section */}
            </main>

        </>
    );
}
