import { Link } from "react-router-dom";

import ContactInfo from "./../data/contact-data.json"
export default function Contact() {
    const phoneOne = "tel:"+ContactInfo.phone1
    const phoneTwo = "tel:"+ContactInfo.phone2
    const emailInfo = "mailto:"+ContactInfo["email-info"]
    const emailSupport = "mailto:"+ContactInfo["email-support"]
    const social = ContactInfo.social
    return(
        <>
            <main className="main">
                {/* Page Title */}
                <div
                    className="page-title dark-background"
                    data-aos="fade"
                    style={{ backgroundImage: "url(assets/img/contact-page-title-bg.jpg)" }}
                >
                    <div className="container">
                    <h1>Contact</h1>
                    <nav className="breadcrumbs">
                        <ol>
                        <li>
                        <   Link to="/">Home</Link>
                        </li>
                        <li className="current">Contact</li>
                        </ol>
                    </nav>
                    </div>
                </div>
                {/* End Page Title */}
                {/* Contact Section */}
                <section id="contact" className="contact section">
                    <div
                    className="container position-relative"
                    data-aos="fade-up"
                    data-aos-delay={100}
                    >
                    <div className="row gy-4">
                        <div className="col-lg-5">
                        <div
                            className="info-item d-flex"
                            data-aos="fade-up"
                            data-aos-delay={200}
                        >
                            <i className="bi bi-geo-alt flex-shrink-0" />
                            <div>
                            <h3>Address</h3>
                            <p dangerouslySetInnerHTML={{__html: ContactInfo.address}} />
                            </div>
                        </div>
                        {/* End Info Item */}
                        <div className="info-item d-flex" data-aos="fade-up" data-aos-delay={300}
                        >
                            <i className="bi bi-telephone flex-shrink-0" />
                            <div>
                            <h3>Call Us</h3>
                            <p><a href={phoneOne}>{ContactInfo.phone1}</a></p>
                            </div>
                        </div>
                        {/* End Info Item */}
                        <div
                            className="info-item d-flex"
                            data-aos="fade-up"
                            data-aos-delay={400}
                        >
                            <i className="bi bi-envelope flex-shrink-0" />
                            <div>
                            <h3>Email Us</h3>
                            <p><a href={phoneOne}>{ContactInfo["email-info"]}</a></p>
                            </div>
                        </div>
                        {/* End Info Item */}
                        </div>
                        <div className="col-lg-7">
                        <form
                            action="forms/contact.php"
                            method="post"
                            className="php-email-form"
                            data-aos="fade-up"
                            data-aos-delay={500}
                        >
                            <div className="row gy-4">
                            <div className="col-md-6">
                                <input
                                type="text"
                                name="name"
                                className="form-control"
                                placeholder="Your Name"
                                required=""
                                />
                            </div>
                            <div className="col-md-6 ">
                                <input
                                type="email"
                                className="form-control"
                                name="email"
                                placeholder="Your Email"
                                required=""
                                />
                            </div>
                            <div className="col-md-12">
                                <input
                                type="text"
                                className="form-control"
                                name="subject"
                                placeholder="Subject"
                                required=""
                                />
                            </div>
                            <div className="col-md-12">
                                <textarea
                                className="form-control"
                                name="message"
                                rows={6}
                                placeholder="Message"
                                required=""
                                defaultValue={""}
                                />
                            </div>
                            <div className="col-md-12 text-center">
                                <div className="loading">Loading</div>
                                <div className="error-message" />
                                <div className="sent-message">
                                Your message has been sent. Thank you!
                                </div>
                                <button type="submit">Send Message</button>
                            </div>
                            </div>
                        </form>
                        </div>
                        {/* End Contact Form */}
                    </div>
                    </div>
                </section>
                {/* /Contact Section */}
                </main>

        </>
    );
}
    