import { Link } from "react-router-dom";
export default function PageNotFound() {
    return(
        <>
            <main className="main">
                {/* Page Title */}
                <div
                    className="page-title dark-background"
                    data-aos="fade"
                    style={{ backgroundImage: "url(assets/img/page-not-found-title.jpg)" }}
                >
                    <div className="container">
                    <h1>Page Not Found</h1>
                    <nav className="breadcrumbs">
                        <ol>
                        <li>
                            <Link to="/">Home</Link>
                        </li>
                        <li className="current">Page Not Found</li>
                        </ol>
                    </nav>
                    </div>
                </div>
                {/* End Page Title */}
                {/* Services Section */}
                <section id="services" className="services section">
                    <div className="mx-auto text-center">
                        <div><img className="page-not-found" src='assets/img/404.jpg' /></div>
                        
                        <div className="mt-3"><a className="btn btn-primary">Go To Home Page</a></div>
                    </div>
                </section>
                {/* /Services Section */}
            </main>
        </>
    );
}